import * as React from "react"
import { Helmet } from "react-helmet"
import { Card, Stack } from "react-bootstrap"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

type DataProps = {
  allMetadataJson: {
    totalCount: number
  }
}

const RoadmapPage: React.FC<PageProps<DataProps>> = ({ data }) => (
  <Layout>
    <Seo title="Roadmap" imageURL="/timeline.jpeg" />
    <Helmet>
      <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How many Sfiggy are available?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We created <b>${data.allMetadataJson.totalCount}</b> unique NFT."
    }
  }, {
    "@type": "Question",
    "name": "Will you add more Sfiggy to the collection?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No, the collection is final."
    }
  }, {
    "@type": "Question",
    "name": "Where can I get my Sfiggy?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The entire collection is available on <a href=https://opensea.io/collection/sfiggy>OpenSea</a>."
    }
  }, {
    "@type": "Question",
    "name": "Where are the Sfiggy stored?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "All the image data and attribute is stored 100% on the blockchain."
    }
  }, {
    "@type": "Question",
    "name": "What right do I have to the artwork?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You are free to do whatever you want with your Sfiggy NFT."
    }
  }]
}
  `}</script>
    </Helmet>
    <Stack gap={5}>
      <Card>
        <Card.Header as="h5">Roadmap</Card.Header>
        <Card.Img variant="top" src="/timeline.jpeg" />
      </Card>
      <Card>
        <Card.Header as="h5">F.A.Q.</Card.Header>
        <Card.Body>
          <Card.Title>How many Sfiggy NFT are available?</Card.Title>
          <Card.Text>
            We created {data.allMetadataJson.totalCount} unique NFT
          </Card.Text>
          <Card.Title>Will you add more Sfiggy to the collection?</Card.Title>
          <Card.Text>No, the collection is final</Card.Text>
          <Card.Title>Where can I get my Sfiggy?</Card.Title>
          <Card.Text>
            The entire collection is available on{" "}
            <a href="https://opensea.io/collection/sfiggy">OpenSea</a>
          </Card.Text>
          <Card.Title>Where are the Sfiggy stored?</Card.Title>
          <Card.Text>
            All the image data and attribute is stored 100% on the blockchain
          </Card.Text>
          <Card.Title>What right do I have to the artwork?</Card.Title>
          <Card.Text>
            You are free to do whatever you want with your Sfiggy NFT
          </Card.Text>
        </Card.Body>
      </Card>
    </Stack>
  </Layout>
)

export default RoadmapPage

export const query = graphql`
  query HomePageQuery {
    allMetadataJson {
      totalCount
    }
  }
`
